import React from 'react';
import Accordion, { AccordionItem, AccordionTitle, AccordionContent } from "../Accordion";

const AccordionWrap = () => {
    return (
        <div className="agency-accordion max-mb-n30">
            <Accordion>
                <AccordionItem id="one">
                    <AccordionTitle id="one">Expert Team</AccordionTitle>
                    <AccordionContent id="one">Our team of expert designers &amp; developers will give you the best product experience with both websites and digital branding.</AccordionContent>
                </AccordionItem>
                <AccordionItem id="two">
                    <AccordionTitle id="two">Best Digital Brand</AccordionTitle>
                    <AccordionContent id="two">We have been chosen as one of the best digital brands in the market for excellent customer reviews.</AccordionContent>
                </AccordionItem>
                <AccordionItem id="three">
                    <AccordionTitle id="three">Creative Ideas</AccordionTitle>
                    <AccordionContent id="three">Our Creatively genius managers give excellent ideas that work in the real world.</AccordionContent>
                </AccordionItem>
            </Accordion>
        </div>
    )
}

export default AccordionWrap;
