import React from 'react'
import {NavLink} from 'react-router-dom';

const NavBar = () => {
    return (
        <nav className="site-main-menu">
            <ul>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/"}><span className="menu-text">Home</span></NavLink>
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/about-livasys"}><span className="menu-text">About Us</span></NavLink>
                </li>
                <li className="has-children">
                    <NavLink to={process.env.PUBLIC_URL + "/services"}><span className="menu-text">Services</span></NavLink>
                    <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/best-web-designing-company"}><span className="menu-text">Web Designing</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/web-development-company"}><span className="menu-text">Web Development</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/wordpress-development`}><span className="menu-text">WordPress Development</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/website-redesign-agencies"}><span className="menu-text"> Website Redesign</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/web-maintainance-company"}><span className="menu-text"> Website Maintenance </span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/graphic-design-services`}><span className="menu-text">Graphic Designing</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/logo-design-services"}><span className="menu-text">Logo Designing</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/web-hosting-service-provider"}><span className="menu-text">Web Hosting</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/web-application-development`}><span className="menu-text">Web Application</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/responsive-web-design"}><span className="menu-text">Responsive Web Designing</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/digital-marketing-agency-in-hyderabad"}><span className="menu-text">Digital Marketing</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/social-media-marketing-company-in-hyderabad"}><span className="menu-text">SMM </span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/search-engine-optimization-services-in-hyderabad`}><span className="menu-text">SEO </span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/search-engine-marketing-services-in-hyderabad"}><span className="menu-text">SEM </span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/social-media-optimization-services"}><span className="menu-text">SMO </span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/local-seo-services-hyderabad`}><span className="menu-text">Local SEO</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/cms-development-companies"}><span className="menu-text">CMS</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/bulk-sms-service-in-hyderabad"}><span className="menu-text">Bulk SMS</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/whatsapp-marketing-services`}><span className="menu-text">Whatsapp Marketing</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/domain-registration-services"}><span className="menu-text">Domain Registration</span></NavLink></li>
                    </ul>
                </li>
                <li className="has-children">
                    <NavLink to={process.env.PUBLIC_URL + "/courses"}><span className="menu-text">Courses</span></NavLink>
                    <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/web-designing-course-in-hyderabad"}><span className="menu-text">Web Designing Course</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/digital-marketing-course-in-hyderabad`}><span className="menu-text"> Digital Marketing Course</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/php-training-in-hyderabad"}><span className="menu-text">PHP Course</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/wordpress-training-in-hyderabad"}><span className="menu-text">WordPress Course</span></NavLink></li>
                    </ul>
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/our-clients"}><span className="menu-text">Our Clients</span></NavLink>
                </li>
                {/* <li>
                    <NavLink to={process.env.PUBLIC_URL + "/contact"}><span className="menu-text">Contact Us</span></NavLink>
                </li> */}
            </ul>
        </nav>
    )
}

export default NavBar
