// import React, { useEffect, useRef } from "react";
// import React, { useState, useEffect, useRef } from "react";
import {Link} from "react-router-dom";
// import Parallax from 'parallax-js';
// import ModalVideo from "react-modal-video";

const HomeAboutThree = () => {
    // const [isOpen, setOpen] = useState(false)
    // const sceneEl = useRef(null);
    // useEffect(() => {
    //     const parallaxInstance = new Parallax(sceneEl.current, {
    //     relativeInput: true,
    //     })
        
    //     parallaxInstance.enable();

    //     return () => parallaxInstance.disable();

    // }, [])
    return (
        <div className="video-section section section-padding-t90-b100">
            <div className="container">
                <div className="row">

                    <div className="col-lg-10 m-auto" data-aos="fade-up">
                        <div className="about-content-full text-center mb-lg-10 mb-0">
                            <h1 className="title">Partner with one of best web development companies in Hyderabad.</h1>
                            <p>Our expert team combines innovation and expertise to deliver best solutions that meet your unique needs. From visually stunning websites to responsive designs that adapt seamlessly across devices, we specialize in creating user-friendly experiences that drive business growth.</p>
                            <Link to={process.env.PUBLIC_URL + "/about-livasys"} className="link"> <mark>Read More</mark> </Link>
                        </div>
                    </div>

                    {/* <div className="col-12">
                        <div className="video-popup-area">
                            <div className="skill-video">
                                <img className="image" src={process.env.PUBLIC_URL + "images/video/about-video.webp"}  alt="video popup" />
                                <button className="icon" onClick={()=> setOpen(true)}><i className="fas fa-play"></i></button>
                                <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="ewcV2OGIvaU" onClose={() => setOpen(false)}/>
                            </div>

                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1">
                                    <img src={process.env.PUBLIC_URL + "images/shape-animation/video-shape-1.webp"} width="141" height="156" alt="Livasys" />
                                </span>
                            </div>
                        </div>
                    </div> */}

                </div>

            </div>
        </div>
    )
}

export default HomeAboutThree;
