import React from 'react';
/**import SEO from '../components/SEO';**/
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
//import AboutFour from '../container/About/AboutFour';
//import Video from '../container/Video/Video';
import ServicesContainer from '../container/Services/ServicesContainer';
//import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import MetaTags from 'react-meta-tags';





const Services = () => {
    return (
        <React.Fragment>
            
            <MetaTags>
                <title>Charge Your Online Growth with our Wide Range of Services</title>
                <meta name="description" content="Discover our wide range of services, including web design, web development, digital marketing, graphic design, WordPress development, domain and hosting services." />
                <meta name="keywords" content="livasys, livasys it solutions services, it company services, it company serives list, top it company services in hyderabad, it company services in hyderabad, it services company, it services companies" />
                <meta property="og:title" content="Charge Your Online Growth with our Wide Range of Services" />
                <meta property="og:image" content="images/blog/770/32.webp" />
            </MetaTags>
            <Header />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg.webp"
                title="Our Services"
                content="Home"
                contentTwo="Our Services"
            />
            {/* <AboutFour />
            <Video /> */}
            <ServicesContainer />
            {/* <TestimonialContainer classOption="bg-primary-blue" /> */}
            <CallToActionTwo />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default Services;



