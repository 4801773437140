import React from 'react';
// import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import ContactInformation from '../container/ContactInformation/ContactInformation';
import ContactFromContainer from '../container/ContactFromContainer/ContactFromContainer';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import MetaTags from 'react-meta-tags';

const Contact = () => {
    return (
        <React.Fragment>
            <MetaTags>
                <title>Contact Us - Livasys IT Solutions | Hyderabad | India</title>
                <meta name="description" content="Contact us for all your digital needs. Our team is ready to assist you with web design & development, digital marketing, graphic design, domain & hosting services.services. " />
                <meta name="keywords" content="livasys, livasys it solutions contact, it company contact, it companies in hyderabad with contact details, it companies in hyderabad contact number, it companies in hyderabad contact numbers, it companies in hyderabad details, website designing company contact details" />
                <meta property="og:title" content="Contact Us - Livasys IT Solutions | Hyderabad | India" />
                <meta property="og:image" content="images/blog/770/32.webp" />
            </MetaTags>
            <Header />
            <Breadcrumb 
                image="images/bg/breadcrumb-bg-five.webp"
                title="Bring your digital visions to life"
                content="Home"
                contentTwo="Contact Us"
            />
            <ContactInformation />
            <ContactFromContainer />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default Contact;

