import React from 'react';
import Typed from 'react-typed';
import {Link} from "react-router-dom";
//import clip from '../../../images/hero-image/bg-video.mp4'; 
//import Poster from '../../../images/hero-image/bg1';  hero-1
//import Poster from '../../assets/images/hero-image/bg1.svg'; 
//import clip from '../../assets/images/hero-image/bg-video.mp4'; 

const IntroThree = () => {
    return (
        
        <div className="intro-section section overlay" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/hero-image/bg-image.gif)`}}  >
            {/* <video autoPlay loop muted poster={Poster}>
                     <source src={clip} type='video/mp4' />
                    <source src={clip} type="video/ogg" />  
            </video>  */}
            <div className="container">
                <div className="row row-cols-lg-1 row-cols-1">

                    <div className="col align-self-center">
                        <div className="intro-content-two headline-active text-center mt-xl-8 mt-lg-8 mt-md-8 mt-sm-8 mt-xs-8">
                            <h3 className="title ah-headline">We build most<br/> amazing {" "} 
                            <Typed
                                strings={[
                                    ' Websites',
                                    ' Software']}
                                    typeSpeed={100}
                                    backSpeed={110}
                                    loop  /> </h3>
                            <div className="desc">
                                <h2 className="title1 ah-headline1">As one of the Leading Web Development Companies in Hyderabad, we have a proven track record of creating visually stunning and highly functional websites that drive businesses towards success.</h2>
                            </div>
                            <Link to={process.env.PUBLIC_URL + "/contact"} className="btn btn-primary btn-hover-secondary">Get Started</Link>
                            <Link to={process.env.PUBLIC_URL + "/about-livasys"} className="btn btn-outline-white btn-hover-primary"> Learn More </Link>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default IntroThree
